export function formatDate(dateString: Date): string {
  const date = new Date(dateString);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);

  return `${day}.${month}.${year}`;
}

export function formatTime(dateString: Date): string {
  const date = new Date(dateString);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
}

export function formatDateToFetch(currentDate: Date): string {
  const date = new Date(currentDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Додаємо 1 до місяця, оскільки getMonth() повертає значення від 0 до 11
  const day = String(date.getDate()).padStart(2, "0"); // Додаємо нулі, якщо число менше 10

  return `${year}-${month}-${day}`;
}
