import { SelectOption } from "../components/modals/forms/inputs/SelectInput/SelectInput";
import { IBike } from "../types/bikes.type";
import { IClient } from "../types/clients.type";
import { OrderStatus, PaymentStatus, PaymentType } from "../types/order.type";
import { ResourceType } from "../types/resources.type";
import { ISupplier } from "../types/suppliers.type";
import { WarehouseStatus } from "../types/warehouse.type";
import { getOrderStatus } from "./getOrderStatus";
import { getPaymentStatus } from "./getPaymentStatus";
import { getPaymentType } from "./getPaymentType";
import { getStatusWarehouse } from "./getWarehouseStatus";

export const createSelectorSupplier = (data: ISupplier[]): SelectOption[] => {
  return data.map((item) => ({ value: item.id, label: item.name }));
};

export const createClientFilterSelector = (data: IClient[]): SelectOption[] => {
  const selector: SelectOption[] = [
    {
      value: "none",
      label: "Будь-який",
    },
  ];
  const clients: SelectOption[] = data.map((item: IClient) => ({
    value: item.id,
    label: item.fullName,
  }));
  return [...selector, ...clients];
};

export const resourceTypeSelector: SelectOption[] = [
  {
    value: ResourceType.ACCESSOIRES,
    label: "Комплектуючі",
  },
  {
    value: ResourceType.CONSUMERS,
    label: "Розсхідники",
  },
];

export const resourceTypeFilterSelector: SelectOption[] = [
  {
    value: "none",
    label: "Будь-який",
  },
  {
    value: ResourceType.ACCESSOIRES,
    label: "Комплектуючі",
  },
  {
    value: ResourceType.CONSUMERS,
    label: "Розсхідники",
  },
];

export const warehouseStatusSelector: SelectOption[] = [
  {
    value: WarehouseStatus.IN_STOCK,
    label: getStatusWarehouse(WarehouseStatus.IN_STOCK),
  },
  {
    value: WarehouseStatus.IS_EXPECTED,
    label: getStatusWarehouse(WarehouseStatus.IS_EXPECTED),
  },
];

export const warehouseStatusFilterSelector: SelectOption[] = [
  {
    value: "none",
    label: "Будь-який",
  },
  {
    value: WarehouseStatus.IN_STOCK,
    label: getStatusWarehouse(WarehouseStatus.IN_STOCK),
  },
  {
    value: WarehouseStatus.IS_EXPECTED,
    label: getStatusWarehouse(WarehouseStatus.IS_EXPECTED),
  },
  {
    value: WarehouseStatus.SHORTAGE,
    label: getStatusWarehouse(WarehouseStatus.SHORTAGE),
  },
];

export const orderStatusPaymentSelector: SelectOption[] = [
  {
    value: PaymentStatus.PAID,
    label: getPaymentStatus(PaymentStatus.PAID),
  },
  {
    value: PaymentStatus.PREPAID,
    label: getPaymentStatus(PaymentStatus.PREPAID),
  },
  {
    value: PaymentStatus.UNPAID,
    label: getPaymentStatus(PaymentStatus.UNPAID),
  },
];

export const orderStatusPaymentFilterSelector: SelectOption[] = [
  {
    value: "none",
    label: "Будь-який",
  },
  {
    value: PaymentStatus.PAID,
    label: getPaymentStatus(PaymentStatus.PAID),
  },
  {
    value: PaymentStatus.PREPAID,
    label: getPaymentStatus(PaymentStatus.PREPAID),
  },
  {
    value: PaymentStatus.UNPAID,
    label: getPaymentStatus(PaymentStatus.UNPAID),
  },
];

export const orderTypePaymentSelector: SelectOption[] = [
  {
    value: PaymentType.CASH,
    label: getPaymentType(PaymentType.CASH),
  },
  {
    value: PaymentType.NON_CASH,
    label: getPaymentType(PaymentType.NON_CASH),
  },
];

export const orderTypePaymentFilterSelector: SelectOption[] = [
  {
    value: "none",
    label: "Будь-який",
  },
  {
    value: PaymentType.CASH,
    label: getPaymentType(PaymentType.CASH),
  },
  {
    value: PaymentType.NON_CASH,
    label: getPaymentType(PaymentType.NON_CASH),
  },
];

export const orderStatusSelector: SelectOption[] = [
  {
    value: OrderStatus.NEW_ORDER,
    label: getOrderStatus(OrderStatus.NEW_ORDER),
  },
  {
    value: OrderStatus.IN_PROGRESS,
    label: getOrderStatus(OrderStatus.IN_PROGRESS),
  },
  {
    value: OrderStatus.COMPLETED,
    label: getOrderStatus(OrderStatus.COMPLETED),
  },
  {
    value: OrderStatus.SHIPPED,
    label: getOrderStatus(OrderStatus.SHIPPED),
  },
  {
    value: OrderStatus.CANCELED,
    label: getOrderStatus(OrderStatus.CANCELED),
  },
];

export const historySelectedFilter: SelectOption[] = [
  {
    value: "none",
    label: "Будь який",
  },
  {
    label: "Оренда",
    value: "order",
  },
  { label: "Списання", value: "warehouse" },
  { label: "Брак", value: "shortage" },
];

export const resourceOffSelector = (bikes: IBike[]): SelectOption[] => {
  const select: SelectOption[] = bikes.map((item) => ({
    value: item.id,
    label: `${item.number} - ${item.name}`,
  }));

  return [{ value: "off", label: "Зі складу" }, ...select];
};

export const expensesTypeSelector: SelectOption[] = [
  { value: "Оплатив", label: "Оплатив" },
  { value: "Знос", label: "Знос" },
  { value: "Борг", label: "Борг" },
];

// props.bike.note
//   ? { value: props.bike.note, label: props.bike.note }
//   : { value: "none", label: "Оберіть тип" };

export const initExpensesTypeSelector: SelectOption = {
  value: "none",
  label: "Оберіть тип",
};
