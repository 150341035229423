const oneDay = 1000 * 60 * 60 * 24;

export const isTodayOrLess = (date: Date) => {
  const currentDate = new Date(date);
  const toDay = new Date();
  currentDate.setUTCHours(0, 0, 0, 0);
  toDay.setUTCHours(0, 0, 0, 0);

  const differenceInTime = currentDate.getTime() - toDay.getTime();
  const differenceInDay = differenceInTime / oneDay;

  return differenceInDay <= 0;
};

export const isThreeDaysOrLess = (date: Date) => {
  const currentDate = new Date(date);
  const toDay = new Date();
  currentDate.setUTCHours(0, 0, 0, 0);
  toDay.setUTCHours(0, 0, 0, 0);

  const differenceInTime = currentDate.getTime() - toDay.getTime();
  const differenceInDay = differenceInTime / oneDay;

  return differenceInDay <= 3 && differenceInDay >= 0;
};

export const isOneDayOrLess = (date: Date) => {
  const currentDate = new Date(date);
  const toDay = new Date();
  const endDay = new Date(date);
  endDay.setUTCHours(23, 59, 59, 999);
  currentDate.setUTCHours(0, 0, 0, 0);
  toDay.setUTCHours(5, 0, 0, 0);

  const differenceInTime = currentDate.getTime() - toDay.getTime();
  const differenceInDay = differenceInTime / oneDay;

  return (
    toDay.getTime() > currentDate.getTime() &&
    toDay.getTime() < endDay.getTime()
  );
};
