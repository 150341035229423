import { SelectOption } from "../components/modals/forms/inputs/SelectInput/SelectInput";
import { ISupplier } from "./suppliers.type";

export interface IResourceWarehouse {
  id: string;
  name: string;
  price: number;
  quantity: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  provider: ISupplier;
}

export interface IWarehouse {
  id: string;
  avatarUrl: string;
  name: string;
  description: string;
  extinctionUnit: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  resourceWarehouse: IResourceWarehouse[];
}

export enum WarehouseStatus {
  IN_STOCK = "in_stock",
  IS_EXPECTED = "is_expected",
  SHORTAGE = "shortage",
}

export interface IWarehouseCreateDto {
  name?: string;
  price?: number;
  quantity?: number;
  status?: string;
  providerId?: string;
  resourceId?: string;
  id?: string;
}

export interface IWarehouseCreateForm {
  price: number;
  quantity: number;
  status: string;
  providerId: string;
  resourceId: string;
}

export interface IWarehouseEdit {
  id?: string;
  price: string;
  count: string;
  supplier: SelectOption | undefined;
  status: SelectOption | undefined;
  key: number;
}

export interface IWarehouseOff {
  userId: string;
  transactionType: string;
  resources: [
    {
      resourceId: string;
      quantity: number;
    }
  ];
  comment: string;
}

export interface IReturnWarehouse {
  note?: string;
  spendingId: string;
  quantity: number;
}
