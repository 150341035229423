import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { authApiService } from "../../../../api/api";
import { useUser } from "../../../../providers/UserProvider";
import {
  IBike,
  IBikeSpending,
  IBikeSpendingItem,
} from "../../../../types/bikes.type";
import {
  IItems,
  IITemsWriteOf,
  IItemWriteOfToBike,
} from "../../../../types/items.type";
import { RoleType } from "../../../../types/role.type";
import { ISelectsInput } from "../../../../types/selects.type";
import { ISpendingEditNote } from "../../../../types/spending.type";
import { IReturnWarehouse } from "../../../../types/warehouse.type";
import {
  expensesTypeSelector,
  initExpensesTypeSelector,
} from "../../../../utils/createSelector";
import { formatDate, formatTime } from "../../../../utils/formatDate";
import BlueButton from "../../../buttons/BlueButton/BlueButton";
import WhiteButton from "../../../buttons/WhiteButton/WhiteBotton";
import SelectChangeProdListInput from "../../../inputs/SelectChangeProdListInput/SelectChangedProdListInput";
import ModalBottomButtons from "../buttons/ModakBottomButtons/ModalBottomButtons";
import EditInput from "../inputs/EditInput/EditInput";
import SelectInput, { SelectOption } from "../inputs/SelectInput/SelectInput";
import ViewInput from "../inputs/ViewInput/ViewInput";
import styles from "./styles.module.scss";

interface Props {
  bike: IBike;
  onClose: () => void;
  onPressEdit: () => void;
  items: IItems[];
  onWriteOf: () => void;
}

const ViewBikeForm: React.FC<Props> = (props) => {
  const { user } = useUser();
  const [isHistory, setIsHistory] = useState<boolean>(false);
  const [history, setHistory] = useState<IBikeSpending[]>([]);
  const [isWriteOff, setIsWriteOff] = useState<boolean>(false);
  const [selectItem, setSelectItem] = useState<ISelectsInput[]>([]);
  const [search, setSearch] = useState<string>("");
  const [note, setNote] = useState<SelectOption>({
    value: "none",
    label: "Оберіть тип",
  });
  const [refreshCount, setRefreshCount] = useState<number>(0);
  const [returnedItem, setReturnedItem] = useState<IBikeSpendingItem | null>(
    null
  );
  const [returnedCount, setReturnedCount] = useState<string>("");
  const [returnedNote, setReturnedNote] = useState<string>("");
  const [isEditNote, setIsEditNote] = useState<IBikeSpending | null>(null);
  const [editedNote, setEditedNote] = useState<SelectOption>(
    initExpensesTypeSelector
  );

  const getBikeInfo = async (id: string) => {
    try {
      const response = await authApiService.getOneBike(id);
      return response;
    } catch (err: any) {
      console.log(err);
      toast.error(err.message);
    }
  };

  useEffect(() => {
    (async () => {
      const response = await getBikeInfo(props.bike.id);

      if (response) {
        setHistory(response.spendings);
      }
    })();
  }, [props.bike, refreshCount]);

  const onWriteOf = async () => {
    if (note.value === "none") {
      toast.error("Заповніть всі поля");
      return;
    }

    try {
      const selected: IITemsWriteOf[] = selectItem
        .filter((item) => item.selectCount && item.selectCount > 0)
        .map((item) => ({ itemId: item.id, quantity: item.selectCount! }));

      if (selected.length === 0) {
        toast.error("Треба обрати запчастину для списання");
        return;
      }

      if (user?.id) {
        const body: IItemWriteOfToBike = {
          bikeId: props.bike.id,
          items: selected,
          userId: user.id,
          note: note.value,
        };

        const response = await authApiService.writeOfItemsToBike(body);

        if (response) {
          toast.success("Запчастини списані успішно");
          setIsWriteOff(false);
          setSelectItem([]);
          setRefreshCount((prevState) => (prevState += 1));
          props.onWriteOf();
        }
      }
    } catch (err: any) {
      console.error(err);
      toast.error(err.message);
    }
  };

  const onPressReturn = async () => {
    try {
      if (returnedItem && user) {
        const body: IReturnWarehouse = {
          note: returnedNote,
          spendingId: returnedItem.id,
          quantity: Number(returnedCount),
        };
        const response = await authApiService.returnWarehouse(body);

        if (response) {
          setReturnedCount("");
          setReturnedItem(null);
          setReturnedNote("");
          setRefreshCount((prevState) => (prevState += 1));
        }
      }
    } catch (err: any) {
      toast.error(err.response.data.message);
      console.error(err);
    }
  };

  const onPressEditNote = async () => {
    try {
      if (editedNote.value === "none") {
        toast.error("Оберіть тип з зазначених у списку");
        return;
      }
      if (isEditNote) {
        const body: ISpendingEditNote = {
          note: editedNote.value,
        };

        const response = await authApiService.editSpendingNote(
          isEditNote.id,
          body
        );

        if (response) {
          setIsEditNote(null);
          setEditedNote(initExpensesTypeSelector);
          setRefreshCount((prevState) => (prevState += 1));
        }
      }
    } catch (err: any) {
      toast.error(err.response.data.message);
      console.error(err.message);
    }
  };

  return (
    <div>
      {isEditNote ? (
        <>
          <div className={`${styles.form} ${styles.pb}`}>
            <SelectInput
              title="Тип"
              value={editedNote}
              values={expensesTypeSelector}
              onChange={(value) => setEditedNote(value)}
            />
          </div>
          <ModalBottomButtons
            backTitle="Назад"
            onPressBack={() => {
              setIsEditNote(null);
              setEditedNote(initExpensesTypeSelector);
            }}
            applyTitle="Зберегти"
            onPressApply={onPressEditNote}
          />
        </>
      ) : returnedItem ? (
        <>
          <div className={styles.form}>
            <div className={styles.dateContainer}>
              <ViewInput title="Запчастина" value={returnedItem.item.name} />
              <ViewInput
                title="Кількість"
                value={returnedItem.quantity.toString()}
              />
            </div>
            <EditInput
              value={returnedCount}
              setValue={(value) => {
                const numberValue = Number(value);
                if (isNaN(numberValue)) {
                  toast.error("Поле очікує числове значення");
                  return;
                }
                if (numberValue > returnedItem.quantity) {
                  toast.error("Не можно повернути більше ніж було списано");
                  return;
                }
                setReturnedCount(value);
              }}
              title="Повернути"
              isRequired
            />
            <EditInput
              title="Нотатка"
              isBig
              value={returnedNote}
              setValue={(value) => setReturnedNote(value)}
              isRequired={false}
            />
          </div>
          <ModalBottomButtons
            backTitle="Назад"
            onPressBack={() => {
              setReturnedItem(null);
              setReturnedCount("");
            }}
            applyTitle="Повернути"
            onPressApply={onPressReturn}
            isApplyDisabled={!returnedCount}
          />
        </>
      ) : isWriteOff ? (
        <>
          <div className={styles.form}>
            <SelectChangeProdListInput
              title="Запчастина"
              items={props.items.map((item) => ({
                id: item.id,
                name: item.name,
                subName: `${item.price.toString()} (грн за од.)`,
                count: item.quantity,
              }))}
              isMultiSelect
              values={selectItem}
              onClickActive={(value) =>
                setSelectItem((prevState) =>
                  prevState.filter((item) => item.id !== value.id)
                )
              }
              onChangeCount={(id, value) => {
                const index = selectItem.findIndex((item) => item.id === id);

                if (index !== -1) {
                  const newState = [...selectItem];
                  newState[index].selectCount = value;
                  setSelectItem(newState);
                }
              }}
              search={search}
              setSearch={(value) => setSearch(value)}
              onClickInactive={(value) =>
                setSelectItem((prevState) => [...prevState, value])
              }
            />
            {selectItem.length > 0 && (
              <SelectInput
                value={note}
                onChange={(value) => setNote(value)}
                values={expensesTypeSelector}
                title="Тип"
              />
            )}
            {selectItem.map((item, index) => (
              <div key={index} className={styles.dateContainer}>
                <ViewInput title="Запчастина" value={item.name} />
                <ViewInput
                  title="Кількість"
                  value={
                    item.selectCount
                      ? item.selectCount.toString()
                      : "Не вказано"
                  }
                />
              </div>
            ))}
          </div>
          <ModalBottomButtons
            backTitle="Назад"
            onPressBack={() => {
              setIsWriteOff(false);
              setSelectItem([]);
            }}
            applyTitle="Списати"
            onPressApply={onWriteOf}
          />
        </>
      ) : isHistory ? (
        <>
          <div className={styles.form}>
            {history.map((item) => (
              <div className={styles.historyContainer} key={item.id}>
                <br />
                <ViewInput
                  title="Час та дата списання"
                  value={`${formatTime(item.createdAt)} ${formatDate(
                    item.createdAt
                  )}`}
                />
                {item.spendingItems.map((spend) => (
                  <>
                    <div className={styles.dateContainer} key={spend.id}>
                      <ViewInput title="Запчастина" value={spend.item.name} />
                      <ViewInput
                        title="Кількість"
                        value={spend.quantity.toString()}
                      />
                    </div>
                    {user?.role === RoleType.ADMIN && (
                      <WhiteButton
                        onClick={() => setReturnedItem(spend)}
                        title="Повернути"
                        width
                      />
                    )}
                  </>
                ))}
                {item.note && <ViewInput title="Нотатка" value={item.note} />}
                <BlueButton
                  onClick={() => {
                    setIsEditNote(item);
                    setEditedNote(
                      item.note
                        ? { value: item.note, label: item.note }
                        : { value: "none", label: "Оберіть тип" }
                    );
                  }}
                  title="Редагувати нотатку"
                  isBigButton
                />
              </div>
            ))}
          </div>
          <ModalBottomButtons
            backTitle="Назад"
            onPressBack={() => setIsHistory(false)}
            applyTitle="Списати"
          />
        </>
      ) : (
        <>
          <div className={styles.form}>
            <ViewInput
              title="Обліковий номер"
              value={props.bike.number}
              isCopied
            />
            {props.bike.name && (
              <ViewInput title="Назва" value={props.bike.name} isCopied />
            )}
            {props.bike.gps && (
              <ViewInput title="GPS, SIM car" value={props.bike.gps} />
            )}
            {props.bike.frame && (
              <ViewInput title="№ рами" value={props.bike.frame} />
            )}
            <ViewInput
              title="Вартість (грн)"
              value={props.bike.price.toString()}
              isCopied
            />
            {props.bike.note && (
              <ViewInput title="Нотатка" value={props.bike.note} isBig />
            )}

            <div className={styles.dateContainer}>
              <BlueButton
                onClick={() => setIsWriteOff(true)}
                title="Списати запчастину"
                isBigButton
              />
              {history.length > 0 && (
                <WhiteButton
                  onClick={() => setIsHistory(true)}
                  title="Історія списання"
                  width
                />
              )}
            </div>
          </div>
          <ModalBottomButtons
            backTitle="Назад"
            onPressBack={props.onClose}
            applyTitle="Редагувати"
            onPressApply={props.onPressEdit}
          />
        </>
      )}
    </div>
  );
};

export default ViewBikeForm;
